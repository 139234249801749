<template>
  <div id="contact">
    <Header></Header>
    <div class="content-wrapper">
      <div class="breadcrumb-wrap bg-f br-1">
        <div class="overlay bg-black op-9"></div>
        <img :src="shape1" alt="Image" class="br-shape-1" />
        <img :src="shape2" alt="Image" class="br-shape-2" />
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="breadcrumb-title">
                <h2>Contact Us</h2>
                <ul class="breadcrumb-menu list-style">
                  <!--<li><a href="index-2.html">Home </a></li>-->
                  <router-link to="/" style="color: white"
                    >Home &nbsp;&rarr;</router-link
                  >
                  <li>Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="contact-wrap pt-100 pb-100">
        <div class="container">
          <div class="row gx-5">
            <div class="col-md-6">
              <div class="contact-form">
                <form
                  @submit.prevent="sendMessage"
                  class="form-wrap"
                  id="contactForm"
                >
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="firstname">First Name</label>
                        <input
                          type="text"
                          name="firstname"
                          v-model="form.firstname"
                          placeholder="First Name*"
                          id="firstname"
                          required
                          data-error="Please Enter Your First Name"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="lastname">Last Name</label>
                        <input
                          type="text"
                          name="lastname"
                          v-model="form.lastname"
                          placeholder="First Name*"
                          id="lastname"
                          required
                          data-error="Please Enter Your Last Name"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="email">Email Address</label>
                        <input
                          type="email"
                          name="email"
                          v-model="form.email"
                          id="email"
                          required
                          placeholder="Email Address*"
                          data-error="Please Enter Valid Email"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label for="phone">Phone Number</label>
                        <input
                          type="text"
                          name="phone"
                          v-model="form.phone"
                          placeholder="Phone Number"
                          id="phone"
                          required
                          data-error="Please Enter Your Phone Number"
                        />
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group v1">
                        <label for="message">Your Message</label>
                        <textarea
                          name="message"
                          id="message"
                          v-model="form.message"
                          placeholder="Your Messages.."
                          cols="30"
                          rows="10"
                          required
                          data-error="Please Enter Your message"
                        ></textarea>
                        <div class="help-block with-errors"></div>
                      </div>
                    </div>
                    <!-- <div class="col-lg-12">
                      <div class="checkbox style2">
                        <input type="checkbox" id="test_1" />
                        <label for="test_1"
                          >I consent to having this website store my submitted
                          information</label
                        >
                      </div>
                    </div> -->
                    <div class="col-lg-12">
                      <button
                        type="submit"
                        class="btn style1 w-100 d-block op-95 mt-20"
                      >
                        Send Message
                      </button>
                      <div class="clearfix"></div>
                    </div>
                    <span class="text-danger text-lg">{{ error }}</span>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-md-6">
              <div class="contact-form">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10022.191611033984!2d-113.9617031!3d51.0983505!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537165fb35118333%3A0x1d493837fe711411!2sFatima%20Karahi%20Corner!5e0!3m2!1sen!2sin!4v1700565621212!5m2!1sen!2sin"
                  width="100%"
                  height="450"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        class="contact-wrap style3 bg-f contact-bg-2 ptb-100 contact-page"
      >
        <div class="container pos-rel">
          <img :src="agent1" alt="Image" class="agent-img md-none" />
          <div class="section-title style6 text-center mb-30">
            <span>
              <img :src="sectionshape1" alt="Image" />
              Contact Us
              <img :src="sectionshape2" alt="Image" />
            </span>
            <h2 class="text-white">
              Not Sure What To Order? <br />
              Download Our App
              <div class="download-app-btn">
                <a href="#"><img :src="googleplaystore" alt="Image" /></a>
                <a href="#"><img :src="appleplaystore" alt="Image" /></a>
              </div>
            </h2>
          </div>
          <div class="row">
            <div class="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <div class="contact-btn">
                <a href="tel:4034522575" class="btn style1"
                  >Make A Call<i class="flaticon-right-arrow-2"></i
                ></a>
                <!--<a href="contact.html" class="btn style3"
                  >Contact Us<i class="flaticon-right-arrow-2"></i
                ></a>-->
                <!--<router-link to="/contact" class="btn style3"
                  >Contact Us<i class="flaticon-right-arrow-2"></i
                ></router-link>-->
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="contact-wrap style2 pb-100 contact-page2">
        <!--<div class="map-bg bg-f"></div>-->
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <div class="contact-card">
                <div class="contact-icon">
                  <i class="flaticon-home"></i>
                </div>
                <div class="contact-info">
                  <h4>Our Location</h4>
                  <p>76-55 CASTLERIDGE BLVD NE Calgary, Alberta</p>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <div class="contact-card">
                <div class="contact-icon">
                  <i class="flaticon-mail"></i>
                </div>
                <div class="contact-info">
                  <h4>Our Email</h4>
                  <a href="mailto:fatimakarahi@gmail.com"
                    ><span
                      class="__cf_email__"
                      data-cfemail="d4bcb1b8b8bb94b7b5b6b5bafab7bbb9"
                      >fatimakarahi@gmail.com
                    </span></a
                  >
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <div class="contact-card">
                <div class="contact-icon">
                  <i class="flaticon-phone-call"></i>
                </div>
                <div class="contact-info">
                  <h4>Contact Us</h4>
                  <a href="tel:4034522575">403-452-2575</a>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
              <div class="contact-card">
                <div class="contact-icon">
                  <i class="flaticon-wall-clock-1"></i>
                </div>
                <div class="contact-info">
                  <h4>Opening Hour</h4>
                  <p>Mon - Sunday</p>
                  <p>11:30 am — 11:00 pm</p>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-30 text-center">
              <!--<a href="contact.html" class="btn style1"
                >Explore Map <i class="flaticon-right-arrow-2"></i>
              </a>-->
              <!-- <router-link to="/contact" class="btn style1"
                >Contact Us<i class="flaticon-right-arrow-2"></i
              ></router-link> -->
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import { contactUs } from "@/store/api";
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
export default {
  name: "contact",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      sectionshape1: require("@/assets/img/secion-shape-1.png"),
      sectionshape2: require("@/assets/img/secion-shape-2.png"),
      agent1: require("@/assets/img/contact/agent-1.png"),
      googleplaystore: require("@/assets/img/app/google-playstore.png"),
      appleplaystore: require("@/assets/img/app/apple-playstore.png"),
      form: {
        firstname: "",
        lastname: "",
        phone: "",
        email: "",
        message: "",
      },
      error: "",
    };
  },
  methods: {
    sendMessage() {
      this.error = "";
      if (
        !this.form.firstname ||
        !this.form.lastname ||
        !this.form.phone ||
        !this.form.email ||
        !this.form.message
      ) {
        this.error = "*All fields are required.";
      } else {
        contactUs(this.form).then((res) => {
          console.log(res.data);
          if (res.data.success === true) {
            this.form.firstname = null;
            this.form.lastname = null;
            this.form.phone = null;
            this.form.email = null;
            this.form.message = null;
            this.$toast.success("Mail sent successfully.");
          }
        });
      }
    },
  },
  components: {
    Header,
    Footer,
  },
};
</script>
